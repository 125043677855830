import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <h3 {...{
      "id": "interactive-tooltip"
    }}>{`Interactive tooltip`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip__label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip__trigger`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$icon-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip--shown`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$inverse-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip__caret`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$inverse-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`p`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$inverse-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.3913043478261%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAACHklEQVQ4y2NITk5mAAETExMmGRkZRgYKwO3btxkYREREwJyOjg7eqVOn8r1794715s2bbHfu3GED0V+/fmX9//8/Cwh/+PABLoeGWcGGwUBiYiKYBmpiOHv2LOO9e/cYL168yAjkM0ZGRrJYW1tzmJmZcURERDCDxEByQEMYgYaAMYgNxBDD0tLSwLSFhQUzDp+wcnNz8zExMfEC2cz4vAw3FAS6u7t5t23bxvnixQuwVx8+fMh24MABDqBr2KFeZj116hTHypUrOVevXs25a9cujgcPHrDdunWLHehKVC+npKTAvXzu3DmQVxn6+/sJRlBfXx9YLcgwuIHNzc0YCgUFBcG0ra2trJWVVbypqWmiubl5OogNFAsC4igbGxtjkBpxcXFGlFiGuezatWtgAVA4uLi4gMPKwcEhITAw8D8IBwUFAXHwf/+AoP8BgcH/bW3tFkDUOLGA9KB4GR24u7uBDXR0dIwKDg76Hx4W+iMsLPxXWHDAr6hgz2+hfi7/7W2tpoPUuDlaMxNMoPYOjmBFtnZ2CR6ePv9d3L3/uzrZ/bf0Tvuvlbz1v0bilv+GTtELQWo0vOtZCBpoa+fABAlDOw87B5eLto5ux2ytzU8YOsedUItZfVQhZMEVQ+fYSpAade8GZqKykYNbAGFFXf+Jy5PO3uEQrzt7MHhHlzJ6JzQxullpMvKkPmNk+PifkWH3f0bmyOsQxW2ohgIAdi4BTQzRDfcAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Closed and open states for a tooltip",
          "title": "Closed and open states for a tooltip",
          "src": "/static/4f2299ca14da4a30df82625c2f986b41/fb070/tooltip-style-1.png",
          "srcSet": ["/static/4f2299ca14da4a30df82625c2f986b41/d6747/tooltip-style-1.png 288w", "/static/4f2299ca14da4a30df82625c2f986b41/09548/tooltip-style-1.png 576w", "/static/4f2299ca14da4a30df82625c2f986b41/fb070/tooltip-style-1.png 1152w", "/static/4f2299ca14da4a30df82625c2f986b41/c3e47/tooltip-style-1.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Example of closed and open states for a tooltip</Caption>
    <h3 {...{
      "id": "icon-tooltip"
    }}>{`Icon tooltip`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip__trigger svg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$inverse-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip--icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$inverse-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`p`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$inverse-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "defintion-tooltip"
    }}>{`Defintion tooltip`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip__label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip__trigger`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$interactive-04`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip--definition`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$inverse-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`p`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$inverse-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <p>{`Tooltip labels and text should be set in sentence case.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip__label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 / 0.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$label-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`p`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <p>{`All tooltip types have a varying height based on the amount of content they contain.`}</p>
    <h3 {...{
      "id": "interactive-tooltip-1"
    }}>{`Interactive tooltip`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`max-width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`240 / 15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip__trigger svg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height, width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip__trigger`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip--shown`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-top`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.72826086956522%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAB3ElEQVQoz2NgQAIX1FMYwv//A7OLdu1j2P3/P8N/IAYBTyBt+P8Lgw2Qzjl/noFBko9h24+fDAxtjQwMtZUMKOCieioKPxBqCBJgZCAFwAy8AKT3lS5kABm34v9/1rznr8T8Dm2WKu3cbzxp0mHd0///C+1891+89v9/TgYzYyaw65vrMV0IM+yiWgoDA9R1pbv25ST29b2Pb2x8mNja+iKhufV1XHPzg7j2jo+JGze2gtTktXQw1fkXMcy2zUL1KbILD1jmSoDYBWvWVcSlp/8P8fP9Hx4S8j88KOB/mJ/H/4jI4P+Js6b3g9TopgcxYQ02ZAOPmGQrAN3InrNidal/eOR/N3vH324ODv+cXPz/2Xul/XIKK/gfN3NxH0h9g2suK1AvE0w/iqEgw65IRjOovjkDtrVg07bykNKq/94Jab99YyP/WOdM/6PR+umXds/n/9HrLk0AWsq21q6RF2gIFxAzohh4AcmF8f//gNmdu48r1B895Vp3/JRD5+b9Xt3rd3o3HNrr0HR4v2vj8VOqIDXeSKnhEjYvw4APZrLBBFA1IL2X1NMwDYVJ2kIVFt+6yZhy/iRT2rGDTCEPLjD5Pb/DlHDmKlP8hetM6U8egb3YOncLWA9yGAIA1uTkLnxwmQYAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing measurements for an interactive tooltip",
          "title": "Structure and spacing measurements for an interactive tooltip",
          "src": "/static/8e997d3f016633f167e2da5400634632/fb070/tooltip-style-2.png",
          "srcSet": ["/static/8e997d3f016633f167e2da5400634632/d6747/tooltip-style-2.png 288w", "/static/8e997d3f016633f167e2da5400634632/09548/tooltip-style-2.png 576w", "/static/8e997d3f016633f167e2da5400634632/fb070/tooltip-style-2.png 1152w", "/static/8e997d3f016633f167e2da5400634632/c3e47/tooltip-style-2.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Structure and spacing measurements for an interactive tooltip | px / rem
    </Caption>
    <h3 {...{
      "id": "definition-tooltip"
    }}>{`Definition tooltip`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip--definition__bottom`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`.bx--tooltip--definition__top`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`max-width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`176 / 11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip--definition__bottom`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`.bx--tooltip--definition__top`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-top, padding bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip--definition__bottom`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`.bx--tooltip--definition__top`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left, padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip__trigger`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip__caret`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-top`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-02`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "39.130434782608695%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAABVUlEQVQoz2NgAIKL6qkgiuHE0/8Mc/VywOye6/8Zai59Zaj//J+h6PRFhuqTJxlijhxmMD99Aiw/8f9/BoaaSgasAGbgaQYDhnvSUQyzUhYz4ANWK1cwEAQwQ2HAKaNDymfZYRn3vSfFGv//5/7//z+r3///Qgy1lbwMNRWMYEW1lRCMy7BNMW2MZxRjRf/7LOHIXbpiVUJzy/OExsbrCc3NN8LLSm9FT5t2ofr/f0GQ2vSDB7AbhmJgfCfjOaV4hQ+O3UIFa9YeyZow4X9KW9v/1M7O/4mNjf8TZ878G/LgnjxIbceNmwwMdZWEDQUBYHAztJy8ZFp396Fb19GLPj0Hz/m33H/i0nbnoZPdrAW8DHwMDNte/MTt5UMKMXCDqAJgruvYeZThlFoi0OB4hpoDx5gqN+1gjn54hSnowz2mik07mCoOHmWy2rUTrLb/5XusLgQAFD2W6ZGNNugAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing measurements for a definition tooltip",
          "title": "Structure and spacing measurements for a definition tooltip",
          "src": "/static/0b738a436d498d6e6ea0397465ac1cca/fb070/tooltip-style-3.png",
          "srcSet": ["/static/0b738a436d498d6e6ea0397465ac1cca/d6747/tooltip-style-3.png 288w", "/static/0b738a436d498d6e6ea0397465ac1cca/09548/tooltip-style-3.png 576w", "/static/0b738a436d498d6e6ea0397465ac1cca/fb070/tooltip-style-3.png 1152w", "/static/0b738a436d498d6e6ea0397465ac1cca/c3e47/tooltip-style-3.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Structure and spacing measurements for a definition tooltip | px / rem
    </Caption>
    <h3 {...{
      "id": "icon-tooltip-1"
    }}>{`Icon tooltip`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip--icon__top:before`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`.bx--tooltip--icon__bottom:before`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`max-width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`176 / 11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip--icon__top:before`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`.bx--tooltip--icon__bottom:before`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left, padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip--icon__top:before`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`.bx--tooltip--icon__bottom:before`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-top, padding-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 / 0.125`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tooltip__caret`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-top`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "31.929347826086957%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAAAlUlEQVQY02NgwAHyN28G09nrlzFkzVzGaPzgOgPDr8MMDC6hEAW1lQzEA6jikh2bGagGCjZtAtN9//+z5iRXWZZFlrvmbVovwBDgx/z//3/SXZi1ZQsjiBl3+KB+dHXNnPjKqunJJ47pgcRW3LnHSJaXo7duYGBISWCnipf9Z82Es/8z2DAU5xczBezYysCgociAz8sAErE250ApQIoAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing measurements for an icon tooltip",
          "title": "Structure and spacing measurements for an icon tooltip",
          "src": "/static/29ab9df65c9ff3a29b52b5b9a9657fce/fb070/tooltip-style-4.png",
          "srcSet": ["/static/29ab9df65c9ff3a29b52b5b9a9657fce/d6747/tooltip-style-4.png 288w", "/static/29ab9df65c9ff3a29b52b5b9a9657fce/09548/tooltip-style-4.png 576w", "/static/29ab9df65c9ff3a29b52b5b9a9657fce/fb070/tooltip-style-4.png 1152w", "/static/29ab9df65c9ff3a29b52b5b9a9657fce/c3e47/tooltip-style-4.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Structure and spacing measurements for an icon tooltip | px / rem
    </Caption>
    <h3 {...{
      "id": "placement"
    }}>{`Placement`}</h3>
    <p>{`Tooltips should be placed below the tooltip icon unless it is within the height of the tooltip box plus 16px / 1rem of the bottom of the page. In that instance, the tooltip should appear above the icon or defined word. On mobile, tooltips can only appear below the tooltip icon.`}</p>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "90.21739130434783%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsSAAALEgHS3X78AAACqElEQVQ4y41UTU8TURR97YghlYiyIEaiTawhmmhaiKLtfE9n2s60w9BCa9sZKymJBcGPAFEp0IIhJi4UiAnR36BrowvQqCtWGjfgWndqNBpQEri+mZaYENPhJTfnvcyZk/vuPfchtGPRNG1hIBBAsiw7EokE0jTNIUmSgyRJB/7u2OZso+3y+Xy2HJ7ndyfW1tZmod/vr/d4PG68juM4gfdHW1tbm7xebzPHcS6TwzCMvWAwGCSqGYzgKwOOH9FodE1RomuyEvsuhSIQIMlbJoeiaMJWUFEUiySKYlHXs5BOX9jKZnXIpntAT3VupeIKMFSgZHIEAQuWipUfJ8f+L8ixvCVIU/RtWY5CKCRviAK3SUcym1RqcuOcNgodVHjC5JD+HmdNMUswLFuCXCgyJqpxYCPqFh9kwNtdBvfgO3BffgNn1MKUyTmcv1tnm6ExP+eoos+Ynx3Akb/46LFxaeGBnpudyvY9nOlNLsy3o+GRva5ScR8W3GObJfq2YlvrKSP871Au1iZ3q6qtoOdIS2Xz/hlC10Zr1JDjnFWP6YIgLGMbLeGOv8SIQ1zkheAynpic1RSKcqJJmxpi323bpmwYBmQyaTBRz6ZBTych1RMHiiRnKo7YhQ+xEFHN9GZXVxd0atq6qsY2olryj5LsW5M6dSBp3kqLCir2PqSZqg9ptiSGY8AKEWDJs3A6Ng7u/g/Q0vsWfELujsk5Kd0jbDtMi6JlG0aSTpECf9XPskMBlh7uCHUPerWRXLs6UDivxK2Bby4M2WdY+vXTwunf6w3XP66680uLxwpfvh66D3DgBcD+5zh0gAY00F9PTE+4sGDtaeEbGyvdPtjkVOvqiAJCTtvC1zS1+eLsODNzsyjx9Am68vkTKqyuIOn1K4RuDCKiPI52Xvkv7gfmUzRlY7IAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Placement examples for a tooltip",
          "title": "Placement examples for a tooltip",
          "src": "/static/0dcfee1e1b99248ef9eb4c1cc718a63e/fb070/tooltip-style-5.png",
          "srcSet": ["/static/0dcfee1e1b99248ef9eb4c1cc718a63e/d6747/tooltip-style-5.png 288w", "/static/0dcfee1e1b99248ef9eb4c1cc718a63e/09548/tooltip-style-5.png 576w", "/static/0dcfee1e1b99248ef9eb4c1cc718a63e/fb070/tooltip-style-5.png 1152w", "/static/0dcfee1e1b99248ef9eb4c1cc718a63e/c3e47/tooltip-style-5.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Placement examples for a tooltip</Caption>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      